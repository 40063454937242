import React, {useRef} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../../../../../components/Layout';
import Seo from '../../../../../components/SEO';
import QuestionSection from '../../../../../components/QuestionSection';
import {ConnectorIntro, SeeAllArrow, ConnectorOverview, FeaturesButtons} from '../../../../../components/systems';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

const Google = () => {
  const connectorName = 'google';
  const connectorImages = useStaticQuery(graphql`
    query {
      allFile(filter: {absolutePath: {regex: "/systems/google/"}, extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        edges {
          node {
            name
            relativePath
            base
            childImageSharp {
              gatsbyImageData(width: 1140, layout: CONSTRAINED, placeholder: BLURRED, quality: 100, formats: AUTO)
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const edgesImages = connectorImages.allFile.edges;
  const images = {
    overview: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-overview`),
    featureOne: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-02`)[0]?.node,
    logo: edgesImages.filter((edge) => edge.node.name === `${connectorName}`),
    featureThree: edgesImages.filter((edge) => edge.node.name === `system-${connectorName}-feature-03`),
  };

  const connector = {
    intro: {
      logo: images.logo,
      name: 'Google Workspace',
      description: `<u>Google Workspace</u> is a suite of office products that includes collaboration tools such as email, calendar, and meetings as well as productivity tools including word processing, presentations, and spreadsheets. Google Workspace Admin console controls access to applications and work product in the Workspace. Trustle uses the Admin console’s APIs to implement various types of access (temporary, just-in-time, standing, or no access). Trustle also uses the identity data from Google Workspace to improve account linking with other platforms and applications.`,
    },
    buttons: [
      {label: 'Gather User Info', ref: null},
      {label: 'Manage Access', ref: null},
      {label: 'Account Cleanup', ref: null},
      {label: 'Monitor SSO', ref: null},
    ],
    overview: {
      title: 'Trustle w/ Google Workspace',
      overview: 'Overview',
      description: `Trustle connects with Google Workspace to gather a full list of users on the system and to collect usage information—both for Google apps and logins to apps connected to Google Single Sigh-on (SSO). Administrators can use this information to:<br><br>
<ul class='ml-8 list-disc'><li><b>Easily map users</b> to other systems connected to Trustle by using Google’s directory of full names, email address, title, manager, and other profile information</li>
<li><b>Manage access to Google apps</b>, based on user requests and manager approvals</li>
<li><b>Monitor which applications users have signed up for</b> and logged into using Google’s SSO service</li>
<li><b>Maintain usage history</b> to set historical baselines, produce efficient access policies and create audit reports</li></ul><br><br>
As with all Trustle connectors, the Google Workspace system administration includes a dashboard with our “Trustle Score” that indicates how well the system is secured, a Recommendations section to help you improve security on the system, a full list of accounts, and log for troubleshooting and auditing purposes.  `,
    },
    overviewExtend: {
      title: 'Recommendations and Account Cleanup',
      overview: 'Flagging and Processing Unused Accounts',
      description: `Because Workspace apps have work product contained in them, it usually requires a process to cleanup accounts after users no longer use them. To avoid data loss during this process, Trustle enables you to flag accounts that are currently in process. Flagging enables you to quickly identify which accounts are no longer used (or can be used) by the user, while also ensuring that login and usage data doesn’t interfere with the process when admins log in to the accounts to recover that data.<br><br>
Trustle also provide many other recommendations to help secure your Workspace environment. For example, Trustle identifies accounts with permissions that are either not in use or are potentially risky. Such accounts are recommended for “Access Review,” which enables a workflow for re-attestation by the manager or resource owner that the access provided is in line with policy and still warranted. Given Trustle’s ability to offer Temporary and Just-in-Time access to such resources, these accounts are usually put in one of those categories, rather than removing access entirely.<br><br> 
Trustle also enables system owners to identify which users access services infrequently, which can help save on licensing costs by paying for licenses only when in use and needed. `,
    },
  };
  const {intro, buttons, overview, overviewExtend} = connector;

  const scrollRefOverview = useRef(null);
  const scrollRefFeature01 = useRef(null);
  const scrollRefFeature02 = useRef(null);

  const scrollRefTable = {
    0: scrollRefOverview,
    1: scrollRefFeature01,
    2: scrollRefFeature02,
    3: scrollRefFeature02,
  };

  const handleClick = ({index}) => {
    const barHeight = '80px';
    scrollRefTable[index].current.style.scrollMargin = barHeight;
    scrollRefTable[index].current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <Layout
      seo={
        <Seo
          title="Google Workspace System | Trustle"
          description="Trustle uses the Admin console’s APIs to implement various types of access (temporary, just-in-time, standing, or no access). Trustle also uses the identity data from Google Workspace to improve account linking with other platforms and applications."
        />
      }
    >
      <section className="pt-16 overflow-hidden transform bg-gradient-to-b from-gray-200 via-white to-gray-200">
        <div className="flex items-center px-4 pb-12 md:py-8 lg:mx-auto max-w-[1360px] font-bold text-sm md:text-xl text-blue">
          <SeeAllArrow />
        </div>

        <div className="max-w-[1360px] mx-auto text-blue sm:pt-8 text-center">
          {connector && (
            <>
              <ConnectorIntro name={intro?.name} description={intro?.description} logo={intro?.logo} isPageHeader />
              <FeaturesButtons dataButtons={buttons} handleClick={handleClick} />
              <div ref={scrollRefOverview}></div>
              <ConnectorOverview title={overview?.title} description={overview?.description} image={images?.overview} />
              <div ref={scrollRefFeature01}></div>
              <ConnectorOverview
                title={overviewExtend?.title}
                overview={overviewExtend?.overview}
                description={overviewExtend?.description}
                image={images?.featureThree}
              />
              <div ref={scrollRefFeature02}></div>
              {/* To do: update to component */}
              <div className="mx-8 mb-8 md:mb-16">
                <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl">
                  Gather User Info Using Google as your Identity Provider (IdP)
                </h2>
                <p className={`md:text-lg text-left max-w-8xl m-auto mb-8 md:mb-16 text-blue`}>
                  Google Workspace is more than just a collection of productivity apps, it also provides IdP SSO
                  services both to the Google ecosystem and hundreds of other apps that integrate with it. Because
                  Google’s SSO is based on the industry standard Open ID Connect (OIDC), which itself is based on OAuth,
                  most cloud services support this connectivity natively. Using Google’s IdP functionality, you can
                  manage group memberships and personal profile information that will affect users’ privileges in a
                  great many applications.{' '}
                </p>

                <div className="flex flex-col items-center justify-between xl:flex-row">
                  <div className="pb-8 max-w-[604px]">
                    {images?.featureOne && (
                      <GatsbyImage
                        fluid={images.featureOne.fluid}
                        alt="featured"
                        image={getImage(images.featureOne)}
                        className="drop-shadow-xl md:drop-shadow-2xl"
                      />
                    )}
                  </div>
                  <div className="">
                    <div className="max-w-[530px]">
                      <h2 className="mb-4 text-lg font-bold text-left sm:text-3xl no-wrap">
                        Managing Entitlements via Google
                      </h2>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        As an IdP, Google maintains many types of information on people, groups, and entitlements that
                        are of broad use both to Trustle and any connected system.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">Users</div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Google maintains profile information, which is both useful for account linking and entitlement
                        policies. The directory can provide information about a person’s title, manager, photo, email
                        address, and phone number, just to name a few.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">Groups</div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        Groups are useful for mailing lists, calendar invites, team membership, and assigning
                        privileges. You can use Workspace groups in Trustle for requesting and approving access.
                      </p>
                      <div className="mb-4 text-lg font-bold text-left sm:text-xl">Access</div>
                      <p className="className={`md:text-lg text-left m-auto mb-8 text-blue`}">
                        You can use Trustle to display a catalog of apps available and automate approvals and
                        provisioning of access.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <QuestionSection />
    </Layout>
  );
};
export default Google;
